angular.module("app")
    .controller('SearchProductController', SearchProductController)
    .controller('EditProductController', EditProductController);

// TestController.$inject = [];
var perPage = 10;
var maxProductsCount = 50;

function SearchProductController($scope, $uibModal, $log, ProductService, dialog, $sessionStorage) {

    function init(){

        $scope.criteria = $sessionStorage.SearchProductController;

        console.log($scope.criteria);
        
        if(!$scope.criteria){
            $scope.criteria = {
                pageNo: 1,
                itemsPerPage: perPage,
                haveImg: '-1',
                status: '-1'
            };
        }
        
        $scope.search();

        $sessionStorage.SearchProductController = undefined;
    }

    $scope.search = function () {
        ProductService.search($scope.criteria, function (res) {
            $scope.result = res.data;
            $scope.criteria.totalItems = res.totalItems;
        });
    }

    $scope.setCriteria = function () {
        $sessionStorage.SearchProductController = $scope.criteria;
    }

    init();
    
}


function EditProductController($scope, $log, ProductService, $stateParams, dialog, $sessionStorage, $uibModal) {
    $scope.title = "แก้ไขข้อมูลรูปภาพสินค้า"
    $scope.maxProductsCount = maxProductsCount;

    $scope.img1;
    $scope.img2;
    $scope.img3;
    $scope.img4;
    $scope.img5;

    $scope.$watch("img1", function () { if ($scope.img1) $scope.test() });
    var id = $stateParams.id;

    search();

    function search() {
        ProductService.get({ id: id }, function (res) {
            $scope.product = res.data;
            console.log($scope.product);
        })
    }


    $scope.deleteImg = function (imgNo) {

        var data = {
            productId: id,
            imgNo: imgNo,
            imgCount: $scope.product.imgCount
        }
        ProductService.deleteImg(data, function (res) {
            search();
        })
    }

    $scope.openUploadImgModal = function (imgNo) {

        $scope.errorMessage = undefined;

        var data = {
            productId: id,
            imgNo: imgNo
        }

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'uploadProductImgModal.html',
            size: 'md',
            controller: function ($scope, $uibModalInstance, ProductService) {
                $scope.model = angular.copy(data);

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('');
                }
                $scope.ok = function () {

                    $scope.errorMessage = undefined;

                    if (!$scope.productImg) {
                        return;
                    }

                    console.log($scope.productImg);

                    var fileInput = document.getElementById('previewImg');

                    var image = new Image();

                    image.src = fileInput.src;

                    if (image.height > 1024 || image.width > 1024) {
                        $scope.errorMessage = 'รูปภาพ ต้องมีขนาด 1024x1024 และ ไม่เกิน 1MB';
                        return;
                    }

                    $scope.model.updatedBy = "admin";
                    var fd = new FormData();

                    fd.append('productImg', $scope.productImg);
                    fd.append('model', JSON.stringify($scope.model));

                    ProductService.uploadImg(fd, function (res) {
                        $uibModalInstance.close();
                    })

                }

            }
        });
        modalInstance.result.then(function () {
            search();
        }, function () { });
    }

}
